import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  Chip,
  Snackbar,
  Alert,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import api from "../api";
import Poll from "../components/Poll";
import MapDialog from "../components/map";

const mockAttendees = [
  { name: "John Doe", email: "john.doe@dowhile.se" },
  { name: "Jane Smith", email: "jane.smith@dowhile.se" },
  { name: "Alice Brown", email: "alice.brown@dowhile.se" },
  { name: "Bob Johnson", email: "bob.johnson@dowhile.se" },
];

const CreateEvent = () => {
  const [attendees, setAttendees] = useState(mockAttendees);
  const [pollModalOpen, setPollModalOpen] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    isPublic: false,
    description: "",
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    location: {
      display_name: "",
      address: "",
      city: "",
      country: "",
    },
    attendees: [],
  });

  const [touched, setTouched] = useState({
    title: false,
    isPublic: false,
    description: false,
    startDate: false,
    endDate: false,
    startTime: false,
    endTime: false,
    location: false,
    attendees: false,
  });

  const handleBlur = (field) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
  };

  const isError = (field) => touched[field] && formData[field].trim() === "";

  const areAllAttendeesSelected =
    formData.attendees.length === attendees.length;

  const [pollFormData, setPollFormData] = useState({
    title: "",
    options: [],
  });

  const [dateErrors, setDateErrors] = useState({
    startDate: false,
    endDate: false,
    startTime: false,
    endTime: false,
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleLocationSelect = (data) => {
    const { latitude, longitude, city, country, address } = data;
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: {
        display_name: `${address} (${city}, ${country}) - [${latitude}, ${longitude}]`,
        address: address,
        city: city,
        country: country,
      },
    }));
    setMapOpen(false);
  };

  const handleChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleAttendeeChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      attendees: value.includes("select-all")
        ? areAllAttendeesSelected
          ? []
          : attendees.map((a) => a.email)
        : value,
    }));
  };

  const handleAttendeeSelectAll = () => {
    const field = "attendees";
    if (areAllAttendeesSelected) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: [],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: attendees.map((a) => a.email),
      }));
    }
  };

  const handleDateError = (field, error) => {
    setDateErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));
  };

  const handlePollModalOpen = () => setPollModalOpen(true);
  const handlePollModalClose = () => setPollModalOpen(false);

  const handlePollFormSubmit = (pollFormData) => {
    setPollFormData(pollFormData);
  };

  useEffect(() => {
    const fetchAttendees = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/admin/attendees`
        );
        if (response.data) {
          setAttendees(response.data);
        } else {
          setAttendees(mockAttendees);
        }
      } catch (error) {
        console.error("Failed to fetch attendees:", error);
        setAttendees(mockAttendees);
      }
    };

    fetchAttendees();
  }, []);

  const validateForm = () => {
    if (!formData.title || !formData.startDate || !formData.startTime)
      return false;
    if (!formData.location?.address) return false;
    if (formData.endTime && !formData.endDate) return false;
    if (
      formData.endDate &&
      formData.startDate &&
      formData.endDate.isBefore(formData.startDate)
    )
      return false;
    if (
      formData.endDate &&
      formData.endTime &&
      formData.startDate &&
      formData.startTime &&
      formData.endDate.isSame(formData.startDate, "day") &&
      formData.endTime.isBefore(formData.startTime)
    ) {
      return false;
    }
    if (
      dateErrors.startDate ||
      dateErrors.endDate ||
      dateErrors.startTime ||
      dateErrors.endTime
    )
      return false;
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    const startDate = formData.startDate
      ? dayjs(formData.startDate).format("YYYY-MM-DD")
      : null;
    const endDate = formData.endDate
      ? dayjs(formData.endDate).format("YYYY-MM-DD")
      : startDate;
    const startTime = formData.startTime
      ? dayjs(formData.startTime).format("HH:mm")
      : null;
    const endTime = formData.endTime
      ? dayjs(formData.endTime).format("HH:mm")
      : dayjs(formData.startTime).add(2, "hour").format("HH:mm");
    let _pollFormData = pollFormData;
    if (
      !pollFormData.title ||
      !pollFormData.options ||
      (pollFormData.options && pollFormData.options.length === 0)
    ) {
      _pollFormData = null;
    }
    const data = {
      name: formData.title,
      is_public: formData.isPublic,
      description: formData.description,
      start_time: dayjs(`${startDate}T${startTime}`).toISOString(),
      end_time: dayjs(`${endDate}T${endTime}`).toISOString(),
      location: formData.location,
      attendees: formData.attendees.map((a) => ({ email: a })),
      poll: _pollFormData,
    };

    try {
      await api.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/events`,
        data
      );
      setSnackbar({
        open: true,
        message: "Event created successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to create event:", error);
      setSnackbar({
        open: true,
        message: "Failed to create event. Please try again.",
        severity: "error",
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 3, width: "100%" }}>
      <Typography variant="h5" gutterBottom>
        Create Event
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Title"
            value={formData.title}
            onChange={(e) => handleChange("title", e.target.value)}
            onBlur={() => handleBlur("title")}
            error={isError("title")}
            helperText={isError("title") ? "Title is required" : ""}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.isPublic}
                onChange={(e) => handleChange("isPublic", e.target.checked)}
              />
            }
            label="Public Event"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Description"
            value={formData.description}
            onChange={(e) => handleChange("description", e.target.value)}
            multiline
            rows={4}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <DatePicker
            label="Start Date"
            value={formData.startDate}
            onChange={(date) => handleChange("startDate", date)}
            sx={{ width: "100%" }}
            slotProps={{
              textField: { fullWidth: true, error: dateErrors.startDate },
            }}
            onError={(error) => handleDateError("startDate", Boolean(error))}
          />
          <DatePicker
            label="End Date"
            value={formData.endDate}
            onChange={(date) => handleChange("endDate", date)}
            sx={{ width: "100%", mt: 2 }}
            slotProps={{
              textField: { fullWidth: true, error: dateErrors.endDate },
            }}
            onError={(error) => handleDateError("endDate", Boolean(error))}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TimePicker
            label="Start Time"
            value={formData.startTime}
            onChange={(time) => handleChange("startTime", time)}
            sx={{ width: "100%" }}
            slotProps={{
              textField: { fullWidth: true, error: dateErrors.startTime },
            }}
            onError={(error) => handleDateError("startTime", Boolean(error))}
          />
          <TimePicker
            label="End Time"
            value={formData.endTime}
            onChange={(time) => handleChange("endTime", time)}
            sx={{ width: "100%", mt: 2 }}
            slotProps={{
              textField: { fullWidth: true, error: dateErrors.endTime },
            }}
            onError={(error) => handleDateError("endTime", Boolean(error))}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Address"
            value={formData.location?.address}
            onClick={() => setMapOpen(true)}
          />
          <Select
            fullWidth
            multiple
            value={formData.attendees}
            onChange={(e) => handleAttendeeChange(e.target.value)}
            displayEmpty
            renderValue={(selected) =>
              selected.length === 0 ? (
                <Typography color="textSecondary">Select Attendees</Typography>
              ) : (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )
            }
            sx={{ mt: 2 }}
          >
            <MenuItem value="select-all" onClick={handleAttendeeSelectAll}>
              <Checkbox checked={areAllAttendeesSelected} />
              Select All
            </MenuItem>
            {attendees.map((attendee) => (
              <MenuItem key={attendee.email} value={attendee.email}>
                <Checkbox
                  checked={formData.attendees.includes(attendee.email)}
                />
                {attendee.email}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handlePollModalOpen}
          >
            Poll
          </Button>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{ mt: 2 }}
            disabled={!validateForm()}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Poll
        open={pollModalOpen}
        onClose={handlePollModalClose}
        onSubmit={handlePollFormSubmit}
      />
      <MapDialog
        open={mapOpen}
        onClose={() => setMapOpen(false)}
        onLocationSelect={handleLocationSelect}
      />
    </Box>
  );
};

export default CreateEvent;
