import React, { useContext, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Badge,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";
import beaconAlarm from "../images/beacon-alarm.png";
import { AppContext } from "../AppContext";

const drawerWidth = 240;
const miniDrawerWidth = 60;

const SearchInput = styled(InputBase)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "4px 8px",
  borderRadius: theme.shape.borderRadius,
  width: "100%",
  display: "flex",
  alignItems: "center",
}));

const Topbar = ({ open, onSidebarToggle }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [anchorElAccount, setAnchorElAccount] = useState(null);
  const { user, logout } = useContext(AppContext);
  const user_info = user ? `${user.name}-${user.email}` : "Armin";

  const handleNotificationsMenu = (event) =>
    setAnchorElNotifications(event.currentTarget);
  const handleAccountMenu = (event) => setAnchorElAccount(event.currentTarget);
  const handleCloseMenu = () => {
    setAnchorElNotifications(null);
    setAnchorElAccount(null);
  };

  const handleLogout = () => {
    setAnchorElNotifications(null);
    setAnchorElAccount(null);
    logout();
  };

  const toggleSearch = () => setSearchOpen(!searchOpen);

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${open ? drawerWidth : miniDrawerWidth}px)` },
        ml: { sm: `${open ? drawerWidth : miniDrawerWidth}px` },
        backgroundColor: (theme) => theme.palette.background.topbar,
      }}
    >
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={onSidebarToggle}>
          <MenuIcon />
        </IconButton>

        <img
          src={beaconAlarm}
          alt="Beacon"
          style={{ width: "55px", height: "auto", marginRight: "auto" }}
        />

        {searchOpen ? (
          <Box
            sx={{
              flexGrow: 1,
              mx: { xs: 2, sm: 4 },
              display: "flex",
              alignItems: "center",
              maxWidth: { xs: "100%", sm: "auto" },
            }}
          >
            <SearchInput
              startAdornment={<SearchIcon color="action" />}
              endAdornment={
                <IconButton size="small" onClick={toggleSearch}>
                  <CloseIcon />
                </IconButton>
              }
              placeholder="Search…"
            />
          </Box>
        ) : (
          <IconButton color="inherit" onClick={toggleSearch}>
            <SearchIcon />
          </IconButton>
        )}

        <Box
          sx={{
            display: { xs: searchOpen ? "none" : "flex", sm: "flex" },
            ml: { xs: 0, sm: 0 },
          }}
        >
          <IconButton color="inherit" onClick={handleNotificationsMenu}>
            <Badge badgeContent={4} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={handleAccountMenu}>
            <AccountCircle />
          </IconButton>

          <Menu
            anchorEl={anchorElNotifications}
            open={Boolean(anchorElNotifications)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleCloseMenu}>Notification 1</MenuItem>
            <MenuItem onClick={handleCloseMenu}>Notification 2</MenuItem>
          </Menu>

          <Menu
            anchorEl={anchorElAccount}
            open={Boolean(anchorElAccount)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleCloseMenu}>{user_info}</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
