import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

const mockEvents = [
  {
    id: 1,
    title: "Team Meeting",
    description: "Monthly team alignment meeting",
    isPublic: true,
    startDate: dayjs().format("YYYY-MM-DD"),
    startTime: dayjs().format("HH:mm"),
    endDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
    endTime: dayjs().add(1, "day").format("HH:mm"),
    address: "123 Main St, Cityville",
  },
  {
    id: 2,
    title: "Team Meeting",
    description: "Monthly team alignment meeting",
    isPublic: true,
    startDate: dayjs().format("YYYY-MM-DD"),
    startTime: dayjs().format("HH:mm"),
    endDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
    endTime: dayjs().add(1, "day").format("HH:mm"),
    address: "123 Main St, Cityville",
  },
  {
    id: 3,
    title: "Team Meeting",
    description: "Monthly team alignment meeting",
    isPublic: true,
    startDate: dayjs().format("YYYY-MM-DD"),
    startTime: dayjs().format("HH:mm"),
    endDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
    endTime: dayjs().add(1, "day").format("HH:mm"),
    address: "123 Main St, Cityville",
  },
  {
    id: 4,
    title: "Team Meeting",
    description: "Monthly team alignment meeting",
    isPublic: true,
    startDate: dayjs().format("YYYY-MM-DD"),
    startTime: dayjs().format("HH:mm"),
    endDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
    endTime: dayjs().add(1, "day").format("HH:mm"),
    address: "123 Main St, Cityville",
  },
  {
    id: 5,
    title: "Team Meeting",
    description: "Monthly team alignment meeting",
    isPublic: true,
    startDate: dayjs().format("YYYY-MM-DD"),
    startTime: dayjs().format("HH:mm"),
    endDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
    endTime: dayjs().add(1, "day").format("HH:mm"),
    address: "123 Main St, Cityville",
  },
  {
    id: 6,
    title: "Team Meeting",
    description: "Monthly team alignment meeting",
    isPublic: true,
    startDate: dayjs().format("YYYY-MM-DD"),
    startTime: dayjs().format("HH:mm"),
    endDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
    endTime: dayjs().add(1, "day").format("HH:mm"),
    address: "123 Main St, Cityville",
  },
];

const Events = () => {
  const [events, setEvents] = useState(mockEvents);

  const columns = [
    { field: "title", headerName: "Title", flex: 1 },
    { field: "description", headerName: "Description", flex: 2 },
    { field: "isPublic", headerName: "Public", type: "boolean", flex: 0.5 },
    { field: "startDate", headerName: "Start Date", flex: 1 },
    { field: "startTime", headerName: "Start Time", flex: 1 },
    { field: "endDate", headerName: "End Date", flex: 1 },
    { field: "endTime", headerName: "End Time", flex: 1 },
    { field: "address", headerName: "Address", flex: 1.5 },
  ];

  return (
    <Box sx={{ p: 3, width: "100%" }}>
      <Typography variant="h5" gutterBottom>
        Events
      </Typography>
      <DataGrid
        rows={events}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        autoHeight
        disableSelectionOnClick
      />
    </Box>
  );
};

export default Events;
