import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Events from "../routes/Events";
import CreateEvent from "../routes/CreateEvent";
import Settings from "../routes/Settings";

const Content = () => (
  <Box sx={{ p: 2 }}>
    <Routes>
      <Route path="/" element={<Navigate to="/events" replace />} />
      <Route path="events" element={<Events />} />
      <Route path="create-event" element={<CreateEvent />} />
      <Route path="settings" element={<Settings />} />
    </Routes>
    <Outlet/>
  </Box>
);

export default Content;
