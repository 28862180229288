import React, { createContext, useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import lightTheme from "./themes/lightTheme";
import darkTheme from "./themes/darkTheme";
import api from "./api";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const token =
      new URLSearchParams(window.location.search).get("token") ||
      localStorage.getItem("token");

    if (token) {
      localStorage.setItem("token", token);
      setIsAuthenticated(true);
      fetchUserData(token);
    }
  }, []);

  const fetchUserData = async (token) => {
    try {
      const response = await api.get("/user-info", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data);
    } catch (error) {
      logout();
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem("token");
  };

  const toggleTheme = () => setDarkMode(!darkMode);

  const theme = createTheme(darkMode ? darkTheme : lightTheme);

  return (
    <AppContext.Provider
      value={{ isAuthenticated, user, logout, darkMode, toggleTheme }}
    >
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default AppProvider;
