import { Box, Toolbar } from "@mui/material";
import React from "react";
import Breadcrumb from "./Breadcrumb";
import Content from "./Content";

const Main = () => {
  return (
    <Box
      component="main"
      sx={{ width: "100%", flexGrow: 1, p: 3 }}
    >
      <Toolbar />
      <Breadcrumb />
      <Content />
    </Box>
  );
};

export default Main;
