import { grey } from '@mui/material/colors';

const darkTheme = {
  palette: {
    mode: 'dark',
    background: {
      default: grey[900],
      sidebar: grey[800],
    },
  },
};

export default darkTheme;