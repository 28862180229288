import React, { createContext, useMemo, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import lightTheme from "./themes/lightTheme";
import darkTheme from "./themes/darkTheme";
import { jwtDecode } from "jwt-decode";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [darkMode, setDarkMode] = useState(false);
  const decodeToken = (token) => {
    try {
      const decoded = jwtDecode(token);
      return decoded;
    } catch (error) {
      return null;
    }
  };
  const onAppStart = () => {
    const token =
      new URLSearchParams(window.location.search).get("token") ||
      localStorage.getItem("token");

    if (token) {
      const decodedToken = decodeToken(token);
      if (decodedToken) {
        setUser({ email: decodedToken.email, name: decodedToken.user_id });
        localStorage.setItem("token", token);
        return { isAuthenticated: true };
      }
    }
    return { isAuthenticated: false };
  };
  const initialState = useMemo(() => onAppStart(), []);
  const [isAuthenticated, setIsAuthenticated] = useState(
    initialState.isAuthenticated
  );

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem("token");
  };

  const toggleTheme = () => setDarkMode(!darkMode);

  const theme = createTheme(darkMode ? darkTheme : lightTheme);

  return (
    <AppContext.Provider
      value={{ isAuthenticated, user, logout, darkMode, toggleTheme }}
    >
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default AppProvider;
