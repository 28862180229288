import React from "react";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
} from "@mui/material";
import SlackIcon from "@mui/icons-material/Chat";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import logo from "../dowhile-logo.png";
import beaconAlarm from "../images/beacon-alarm.png";

const Login = () => {
  const handleLogin = (provider) => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/${provider}`;
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F3F4F6",
      }}
    >
      <Card
        sx={{
          width: "100%",
          maxWidth: 400,
          p: 2,
          boxShadow: 3,
          textAlign: "center",
        }}
      >
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
            <img
              src={logo}
              alt="doWhile"
              style={{ width: "100px", height: "auto" }}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
            <img
              src={beaconAlarm}
              alt="Beacon"
              style={{ width: "150px", height: "auto" }}
            />
          </Box>
          <Stack direction="column" spacing={2}>
            <IconButton
              variant="contained"
              color="primary"
              sx={{
                borderRadius: "10px",
                textAlign: "center",
                padding: "12px 24px",
                color: "#fff",
                backgroundColor: "#4A154B",
                fontSize: "0.875rem",
                "&:hover": { backgroundColor: "#3A1040" },
              }}
              onClick={() => handleLogin("slack")}
            >
              <SlackIcon sx={{ mr: 1 }} />
              Login with Slack
            </IconButton>
            <IconButton
              variant="contained"
              color="primary"
              sx={{
                borderRadius: "10px",
                textAlign: "center",
                padding: "12px 24px",
                color: "#fff",
                backgroundColor: "#0078D4",
                fontSize: "0.875rem",
                "&:hover": { backgroundColor: "#0063B1" },
              }}
              onClick={() => handleLogin("microsoft")}
            >
              <MicrosoftIcon sx={{ mr: 1 }} />
              Login with Microsoft
            </IconButton>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Login;
