import React, { useState } from "react";
import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  Chip,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";

const mockAttendees = ["John Doe", "Jane Smith", "Alice Brown", "Bob Johnson"];

const CreateEvent = () => {
  const [formData, setFormData] = useState({
    title: "",
    isPublic: false,
    description: "",
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    address: "",
    attendees: [],
  });

  const [dateErrors, setDateErrors] = useState({
    startDate: false,
    endDate: false,
    startTime: false,
    endTime: false,
  });

  const handleChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleDateError = (field, error) => {
    setDateErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));
  };

  const validateForm = () => {
    if (!formData.title || !formData.startDate || !formData.startTime)
      return false;
    if (formData.endTime && !formData.endDate) return false;
    if (
      formData.endDate &&
      formData.startDate &&
      formData.endDate.isBefore(formData.startDate)
    )
      return false;
    if (
      formData.endDate &&
      formData.endTime &&
      formData.startDate &&
      formData.startTime &&
      formData.endDate.isSame(formData.startDate, "day") &&
      formData.endTime.isBefore(formData.startTime)
    ) {
      return false;
    }
    if (
      dateErrors.startDate ||
      dateErrors.endDate ||
      dateErrors.startTime ||
      dateErrors.endTime
    )
      return false;
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form Data Submitted:", formData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 3, width: "100%" }}>
      <Typography variant="h5" gutterBottom>
        Create Event
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Title"
            value={formData.title}
            onChange={(e) => handleChange("title", e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.isPublic}
                onChange={(e) => handleChange("isPublic", e.target.checked)}
              />
            }
            label="Public Event"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Description"
            value={formData.description}
            onChange={(e) => handleChange("description", e.target.value)}
            multiline
            rows={4}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <DatePicker
            label="Start Date"
            value={formData.startDate}
            onChange={(date) => handleChange("startDate", date)}
            sx={{ width: "100%" }}
            slotProps={{
              textField: { fullWidth: true, error: dateErrors.startDate },
            }}
            onError={(error) => handleDateError("startDate", Boolean(error))}
          />
          <DatePicker
            label="End Date"
            value={formData.endDate}
            onChange={(date) => handleChange("endDate", date)}
            sx={{ width: "100%", mt: 2 }}
            slotProps={{
              textField: { fullWidth: true, error: dateErrors.endDate },
            }}
            onError={(error) => handleDateError("endDate", Boolean(error))}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TimePicker
            label="Start Time"
            value={formData.startTime}
            onChange={(time) => handleChange("startTime", time)}
            sx={{ width: "100%" }}
            slotProps={{
              textField: { fullWidth: true, error: dateErrors.startTime },
            }}
            onError={(error) => handleDateError("startTime", Boolean(error))}
          />
          <TimePicker
            label="End Time"
            value={formData.endTime}
            onChange={(time) => handleChange("endTime", time)}
            sx={{ width: "100%", mt: 2 }}
            slotProps={{
              textField: { fullWidth: true, error: dateErrors.endTime },
            }}
            onError={(error) => handleDateError("endTime", Boolean(error))}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Address"
            value={formData.address}
            onChange={(e) => handleChange("address", e.target.value)}
          />
          <Select
            fullWidth
            multiple
            value={formData.attendees}
            onChange={(e) => handleChange("attendees", e.target.value)}
            displayEmpty
            renderValue={(selected) =>
              selected.length === 0 ? (
                <Typography color="textSecondary">Select Attendees</Typography>
              ) : (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )
            }
            sx={{ mt: 2 }}
          >
            {mockAttendees.map((attendee) => (
              <MenuItem key={attendee} value={attendee}>
                <Checkbox checked={formData.attendees.includes(attendee)} />
                {attendee}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{ mt: 2 }}
            disabled={!validateForm()}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateEvent;
