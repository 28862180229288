import React, { useContext, useState } from "react";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import Main from "./components/Main";
import { Box, CssBaseline } from "@mui/material";
import { AppContext } from "./AppContext";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Login from "./components/Login";

const App = () => {
  const { isAuthenticated } = useContext(AppContext);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const AuthenticatedApp = () => (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Topbar open={sidebarOpen} onSidebarToggle={handleSidebarToggle} />
      <Sidebar open={sidebarOpen} onClose={handleSidebarToggle} />
      <Main></Main>
    </Box>
  );

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/*"
          element={isAuthenticated ? <AuthenticatedApp /> : <Navigate to="/login" />}
        />
      </Routes>
    </Router>
  );
};

export default App;
