import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, CircularProgress, Typography } from "@mui/material";
import dayjs from "dayjs";
import api from "../api";

const mockEvents = [
  {
    id: 1,
    name: "Team Meeting",
    description: "Monthly team alignment meeting",
    start_time: dayjs().toISOString(),
    end_time: dayjs().toISOString(),
    location: { address: "123 Main St, Cityville" },
    attendees: [{ email: "armin.maarefi@dowhile.se" }],
  },
  {
    id: 2,
    name: "Team Meeting",
    description: "Monthly team alignment meeting",
    start_time: dayjs().toISOString(),
    end_time: dayjs().toISOString(),
    location: { address: "123 Main St, Cityville" },
    attendees: [{ email: "armin.maarefi@dowhile.se" }],
  },
  {
    id: 3,
    name: "Team Meeting",
    description: "Monthly team alignment meeting",
    start_time: dayjs().toISOString(),
    end_time: dayjs().toISOString(),
    location: { address: "123 Main St, Cityville" },
    attendees: [{ email: "armin.maarefi@dowhile.se" }],
  },
];

const Events = () => {
  const [events, setEvents] = useState(mockEvents);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/events`
        );
        if (response.data) {
          setEvents(response.data);
        } else {
          setEvents(mockEvents);
        }
      } catch (error) {
        console.error("Failed to fetch events:", error);
        setEvents(mockEvents);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const columns = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "description", headerName: "Description", width: 150 },
    { field: "start_time", headerName: "Start Time", width: 150 },
    { field: "end_time", headerName: "End Time", width: 150 },
    {
      field: "location",
      headerName: "Location",
      width: 150,
      valueGetter: (params) => params.address,
    },
    {
      field: "attendees",
      headerName: "Attendees",
      width: 150,
      renderCell: (params) =>
        params.row.attendees?.length
          ? params.row.attendees.map((a) => a.email).join(", ")
          : "No attendees",
    },
  ];

  return (
    <Box sx={{ p: 3, width: "100%" }}>
      <Typography variant="h5" gutterBottom>
        Events
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <DataGrid
          rows={events}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          autoHeight
          disableSelectionOnClick
          getRowId={(row) => row.id}
        />
      )}
    </Box>
  );
};

export default Events;
