import React, { useState } from "react";
import {
  Modal,
  Box,
  TextField,
  IconButton,
  Divider,
  Button,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const CustomModal = ({ open, onClose, onSubmit }) => {
  const [title, setTitle] = useState("");
  const [options, setOptions] = useState([""]);

  const handleAddOption = () => {
    setOptions([...options, ""]);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      title,
      options: options.filter((opt) => opt.trim() !== ""),
    };
    onSubmit(formData);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <form>
          <TextField
            fullWidth
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            margin="normal"
            required
          />
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" mb={2}>
            Options
          </Typography>
          {options.map((option, index) => (
            <Box key={index} display="flex" alignItems="center" mb={2}>
              <TextField
                fullWidth
                label={`Option ${index + 1}`}
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                required={index === 0}
              />
              {index === options.length - 1 && (
                <IconButton
                  color="primary"
                  onClick={handleAddOption}
                  sx={{ ml: 1 }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </Box>
          ))}
          <Button fullWidth variant="contained" color="primary" onClick={handleSubmit}>
            Create Poll
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default CustomModal;
