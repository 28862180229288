import React, { useRef } from "react";
import * as atlas from "azure-maps-control";
import { Dialog, DialogTitle, DialogContent, Fade } from "@mui/material";

const AzureMapDialog = ({ open, onClose, onLocationSelect }) => {
  const mapRef = useRef(null);

  const handleDialogEntered = () => {
    if (mapRef.current) {
      const map = new atlas.Map(mapRef.current, {
        center: [11.9746, 57.7089],
        zoom: 10,
        view: "Auto",
        authOptions: {
          authType: "subscriptionKey",
          subscriptionKey: `${process.env.REACT_APP_MAP_KEY}`,
        },
      });

      map.events.add("click", async (e) => {
        if (e.position) {
          const position = e.position;
          const latitude = position[1];
          const longitude = position[0];

          const addressInfo = await reverseGeocode(latitude, longitude);

          onLocationSelect({
            latitude,
            longitude,
            ...addressInfo,
          });
        }
      });

      map.events.add("ready", () => {
        console.log("Azure Map is ready!");
      });

      return () => map.dispose();
    }
  };

  const reverseGeocode = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://atlas.microsoft.com/search/address/reverse/json?subscription-key=${process.env.REACT_APP_MAP_KEY}&api-version=1.0&query=${latitude},${longitude}`
      );
      const data = await response.json();

      if (data && data.addresses && data.addresses.length > 0) {
        const address = data.addresses[0].address;
        return {
          city: address.municipality || "Unknown City",
          country: address.country || "Unknown Country",
          address: address.freeformAddress || "Unknown Address",
        };
      }
      return {
        city: "Unknown City",
        country: "Unknown Country",
        address: "Unknown Address",
      };
    } catch (error) {
      console.error("Reverse geocoding failed:", error);
      return { city: "Error", country: "Error", address: "Error" };
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      TransitionComponent={Fade}
      TransitionProps={{ onEntered: handleDialogEntered }}
    >
      <DialogTitle>Select a Location</DialogTitle>
      <DialogContent>
        <div
          ref={mapRef}
          style={{
            width: "100%",
            height: "500px",
            minHeight: "400px",
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AzureMapDialog;
