import React, { useContext } from "react";
import { Switch, Typography, Box } from "@mui/material";
import { AppContext } from "../AppContext";

const Settings = () => {
  const { darkMode, toggleTheme } = useContext(AppContext);

  return (
    <Box display="flex" alignItems="center">
      <Typography>Change Theme</Typography>
      <Switch checked={darkMode} onChange={toggleTheme} />
    </Box>
  );
};

export default Settings;
