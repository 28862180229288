import React from "react";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  useMediaQuery,
  Box,
  Toolbar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import EventIcon from "@mui/icons-material/Event";
import CreateIcon from "@mui/icons-material/Create";
import SettingsIcon from "@mui/icons-material/Settings";
import logo from "../dowhile-logo.png";
import logo2 from "../doWhile-logo-2.png";
import { Link } from "react-router-dom";

const drawerWidth = 240;
const miniDrawerWidth = 60;

const Sidebar = ({ open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const drawerContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        backgroundColor: (theme) => theme.palette.background.sidebar,
      }}
    >
      <Box>
        <Toolbar
          sx={{
            justifyContent: open ? "start" : "center",
          }}
        >
          <img
            src={open ? logo : logo2}
            alt="doWhile Consulting"
            style={{ height: 30 }}
          />
        </Toolbar>
        <Divider />
        <List>
          <ListItemButton component={Link} to="/events">
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText
              primary="Events"
              sx={{
                opacity: open ? 1 : 0,
                transition: "opacity 0.3s ease",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            />
          </ListItemButton>
          <ListItemButton component={Link} to="/create-event">
            <ListItemIcon>
              <CreateIcon />
            </ListItemIcon>
            <ListItemText
              primary="Create Event"
              sx={{
                opacity: open ? 1 : 0,
                transition: "opacity 0.3s ease",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            />
          </ListItemButton>
        </List>
      </Box>
      <List>
        <ListItemButton component={Link} to="/settings">
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Settings"
            sx={{
              opacity: open ? 1 : 0,
              transition: "opacity 0.3s ease",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          />
        </ListItemButton>
      </List>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: open ? drawerWidth : miniDrawerWidth },
        flexShrink: { sm: 0 },
      }}
    >
      {!isMobile ? (
        <Drawer
          variant="permanent"
          open
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: open ? drawerWidth : miniDrawerWidth,
              overflowX: "hidden",
              transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            },
          }}
        >
          {drawerContent}
        </Drawer>
      ) : (
        <Drawer
          variant="temporary"
          open={open}
          onClose={onClose}
          ModalProps={{ keepMounted: true }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawerContent}
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
