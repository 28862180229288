import { grey, pink } from '@mui/material/colors';

const lightTheme = {
  palette: {
    mode: 'light',
    background: {
      default: grey[100],
      sidebar: grey[200],
      topbar: pink[200]
    },
  },
};

export default lightTheme;